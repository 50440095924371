import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import homeCarousel3 from '../media/rich_speaker_setup.png'
import homeCarousel4 from '../media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'
import sinusTon from '../media/sinusTon.jpeg'


import React, { useState } from 'react';

function ARCube() {

  return (
    <div className='responsiveDiv'>

      <h1 className='mt-[1rem] font-bold'>ARCube</h1>
      <img src={homeCarousel2} className='w-full m-auto'></img>
      <div className='text-left'>
        <p className='text-center italic mt-[1rem]'>The ARCube augemented reality interface.</p>
      <p className='text-left mt-[1rem]'>ARCube is an ongoing project on the use of augmented reality interfaces in combination with hardware musical instruments and controllers.
      We are exploring new ways of composition and performance, with a scope on spatial sound and electronic music.</p>
        <p>The AR interface consists of a semitransparent cuboid with edge lengths of about 30 cm. The augmented cubid can be freely placed in space by the user.
        Four spheres with a diameter of about 2 cm can be freely placed inside or outside of the cube. Their position relative to the cuboid is used as control data, turning the interface into a multi-value X-Y-Z slider.</p>


        <figure class="video">
          <iframe src="https://www.youtube.com/embed/VHrJdQjA2C0?si=zqeI8ZHhCKwUSWQ3" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen"></iframe>
      </figure>
      <p className='text-center italic mt-[1rem]'>Moving virtual objects.</p>

      <h2 className='text-left mb-[1rem]'>ARCube in Action</h2>
   
      <h4 className='text-left'>SinusTon</h4>
      <figure class="video">
          <iframe src="https://www.youtube.com/embed/U4VDXmhU760?si=jGKGP-y_FPQjRiYy&amp;start=949" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen"></iframe>
      </figure>

      <p className='text-center italic mt-[1rem]'>Performing with ARCube at the SinusTon electroacoustic music festival in Magdeburg, Germany.</p>


      
      <h4 className='text-left'>Live Modular Performance</h4>
      <figure class="video">
          <iframe src="https://www.youtube.com/embed/NEWaGivRx3U?si=PguDBq8pYDBGVNTF" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen"></iframe>
      </figure>
      <p className='text-center italic mt-[1rem]'>First performance with the ARCube by Hyunkyung Shin.</p>

      <h4 className='text-left'>Summer School Demo</h4>
      <figure class="video">
          <iframe src="https://youtube.com/embed/Uzv067a4YCc?si=gzlfRadvvfQMGMvC" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen"></iframe>
      </figure>
      <p className='text-center italic mt-[1rem]'>30 high school students having fun with the ARCube.</p>


      <h4 className='text-left'>Usablity Opportunities in Hybrid Spatial Interactions</h4>
      <p className=''>In a first user study we were exploring two questions:
<br></br>
&ensp;&ensp;&ensp;&ensp;&ensp;1: Is the AR interface suited for spatial aound control?
<br></br>
&ensp;&ensp;&ensp;&ensp;&ensp;2: Can the AR interface be used in combination with a traditional MIDI controller.</p>

<img src={homeCarousel4} className='w-full m-auto'></img>
      
        <p className='text-center italic mt-[1rem]'>Participant interacting with the hybrid setup during the UX study.</p>
  

<p>A paper + poster with results of this early stage user experience experiment was presented at AC SUI 2024 in Trier, Germany.</p>

<img src={sinusTon} className='w-full m-auto'></img>
<p className='text-center italic mt-[1rem]'>Performing using the ARCube at the SinusTon electroacoustic music festival in Magdeburg, Germany.</p>

<br></br>

<h4>Related Publication</h4>
 <div className='italic'>  

  <a target="_blank" href="https://dl.acm.org/doi/abs/10.1145/3677386.3688883">
Hyunkyung Shin and Henrik von Coler. 2024. ARCube: Hybrid Spatial Interaction for Immersive Audio. In Proceedings of SUI ’24. ACM,
New York, NY, USA
</a> 
</div>   
 
 
       <br></br>
      <figure class="video">
          <iframe src="https://www.youtube.com/embed/em5akUnJwkA?si=IajJL-TFFn1IS37h" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; modestbranding; showinfo=0; fullscreen"></iframe>
      </figure>
      <p className='text-center italic mt-[1rem]'>First person view during the user experience study.</p>

     </div>

      
     
    
      </div>
    
  );
}

export default ARCube;