import logo from '../logo.svg';
import l42iLogo from '../media/l42i_metal_thumb.png'
import '../App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import homeCarousel1 from '../media/2024_spring_team_crop.jpg'
import homeCarousel2 from '../media/cube4.png'
import homeCarousel3 from '../media/rich_speaker_setup.png'
import homeCarousel4 from '../media/setup_in_use.jpg'
import Card from 'react-bootstrap/Card';
import Henrik from '../media/Henrik_von_Coler.jpg'
import Evan from '../media/evan_murray.jpg'
import Orlando from '../media/Orlando_Kenny.jpeg'
import Xinni from '../media/XinniLi.jpeg'
import Luhee from '../media/luhee.png'
import Ishaan from '../media/ishaan.jpg'
import Anish from '../media/anish.jpeg'
import Alex from '../media/alex.jpeg'
import Henry from '../media/henry.jpeg'
import Jacob from '../media/jacob.jpeg'



import React, { useState } from 'react';

//test

function People() {

  return (
    <div className='teamDiv'>
        <h1 className='mt-[1rem] font-bold'>Meet the Team</h1>
        <div className='teamContainer'>
        
    <div className='cardItem'>
        <Card style={{ width: '18rem' }} className='bg-orange-500'>
            <Card.Img variant="top" src={Henrik} />
            <Card.Body className='text-left'>
                <Card.Title>Henrik von Coler</Card.Title>
                <Card.Text>
                <div className='italic'>Assistant Professor</div>
                <a href="http://hvc.berlin" target='_blank'>Personal Website</a>
                <br></br>
                <a href="http://ii.hvc.berlin" target='_blank'>Reseach Portfolio</a>
                </Card.Text>
            
            </Card.Body>
        </Card>
    </div>

    <div className='cardItem'>
        <Card style={{ width: '18rem' }} className='bg-orange-500'>
            <Card.Img variant="top" src={Luhee} />
            <Card.Body className='text-left'>
                <Card.Title>
                Hyunkyung Shin </Card.Title>
                <Card.Text>
                <div className='italic'>Graduate Research Asistant</div>

                <div className='mt-[1rem] font-bold'>Projects:</div>
              
                    <li>ARCube</li>
                   <li>Schizosymphony</li>
                
                </Card.Text>
            
            </Card.Body>
        </Card>
    </div>

 {/*   <div className='cardItem'>
        <Card style={{ width: '18rem' }} className='bg-orange-500'>
            <Card.Img variant="top" src={Evan} />
            <Card.Body className='text-left'>
                <Card.Title>Evan Murray</Card.Title>
                <Card.Text>
                <div className='italic'>Graduate Student</div>

                <div className='mt-[1rem] font-bold'>Projects:</div>
              
                   <li>Auditory Feedback for Proprioception</li>

                
                </Card.Text>
            
            </Card.Body>
        </Card>
    </div>*/}

    <div className='cardItem'>
        <Card style={{ width: '18rem' }} className='bg-orange-500'>
            <Card.Img variant="top" src={Orlando} />
            <Card.Body className='text-left'>
                <Card.Title>Orlando Kenny</Card.Title>
                <Card.Text>
                    <div className='italic'>Graduate Assistant</div>

                    <div className='mt-[1rem] font-bold'>Projects:</div>
              
                    <li>MËSH - Network Audio Interaction Systems</li>
                   <li>ImmersEND - Mobile Augemted Reality for Spatial Audio</li>


                
                </Card.Text>
            
            </Card.Body>
        </Card>
    </div>


    <div className='cardItem'>
        <Card style={{ width: '18rem' }} className='bg-orange-500'>
            <Card.Img variant="top" src={Xinni} />
            <Card.Body className='text-left'>
                <Card.Title>Xinni Li</Card.Title>
                <Card.Text>
                <div className='italic'>Graduate Student</div>

                <div className='mt-[1rem] font-bold'>Projects:</div>
              
                   <li>Spatial Rendering Systems</li>

                </Card.Text>
            
            </Card.Body>
        </Card>
    </div>


    <div className='cardItem'>
        <Card style={{ width: '18rem' }} className='bg-orange-500'>
            <Card.Img variant="top" src={Ishaan} />
            <Card.Body className='text-left'>
                <Card.Title>Ishaan Jagyasi</Card.Title>
                <Card.Text>
                <div className='italic'>Graduate Student</div>

                
                </Card.Text>
            
            </Card.Body>
        </Card>
    </div>

    <div className='cardItem'>
        <Card style={{ width: '18rem' }} className='bg-orange-500'>
            <Card.Img variant="top" src={Henry} />
            <Card.Body className='text-left'>
                <Card.Title>Henry Windish</Card.Title>
                <Card.Text>
                <div className='italic'>Graduate Student</div>

               
                </Card.Text>
            
            </Card.Body>
        </Card>
    </div>

    <div className='cardItem'>
        <Card style={{ width: '18rem' }} className='bg-orange-500'>
            <Card.Img variant="top" src={Anish} />
            <Card.Body className='text-left'>
                <Card.Title>Anish Nair</Card.Title>
                <Card.Text>
                <div className='italic'>Undergraduate Student</div>

               
                </Card.Text>
            
            </Card.Body>
        </Card>
    </div>

    <div className='cardItem'>
        <Card style={{ width: '18rem' }} className='bg-orange-500'>
            <Card.Img variant="top" src={Jacob} />
            <Card.Body className='text-left'>
                <Card.Title>Jacob Westerstahl</Card.Title>
                <Card.Text>
                <div className='italic'>Undergraduate Student</div>

              
                </Card.Text>
            
            </Card.Body>
        </Card>
    </div>

    <div className='cardItem'>
        <Card style={{ width: '18rem' }} className='bg-orange-500'>
            <Card.Img variant="top" src={Alex} />
            <Card.Body className='text-left'>
                <Card.Title>Alex Akstens</Card.Title>
                <Card.Text>
                <div className='italic'>Undergraduate Student</div>

                
                </Card.Text>
            
            </Card.Body>
        </Card>
    </div>
    

       

    
</div>

</div>
  );
}

export default People;